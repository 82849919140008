import { TabHeading } from "../UiComponents";
import PatientMPIToolBaseline from "../../../mpi/tools/PatientMPIToolBaseline";
import moment from "moment";
import { isDebugMode } from "../../../../helpers/UiHelper";
import CorEventsTool from "../../../mpi/tools/CorEventsTool";

export default function RiskPanel(props) {
  const { id, unit, date } = props;
  const showCorEvent =
    (window.location.href.indexOf(".xyz") > 0 ||
      window.location.href.indexOf("localhost") > 0) &&
    isDebugMode();
  // console.log(showCorEvent);

  return (
    <div>
      {/* <TabHeading className="patient-statistics-heading">RISK</TabHeading> */}

      <div style={{ width: "100%" }}>
        {/*<EmptyChartTool/>*/}
        {/* <PatientMPITool uuid={id} t={+moment(date).endOf("day")} /> */}
        {showCorEvent && <CorEventsTool id={id} date={date} />}

        <PatientMPIToolBaseline
          uuid={id}
          t={+moment(date).endOf("day")}
          unit={unit}
        />
      </div>
    </div>
  );
}
